<template>
    <div>
        <Quote/>
        <GoBack next="Promo kodni tahrirlash"/>
        <div class="row justify-content-center">
            <div class="col-11 col-md-8">
                <div class="ABC">
                    <label for="promo" class="form-label">Promo kodni o'zgartiring</label>
                    <div class="mb-3 d-flex">
                        <input
                            id="promo"
                            class="form-control"
                            v-model="addPromo.promo"
                            disabled
                        >
                        <button
                            class="ms-1 px-2 btn btn-danger"
                            @click="showDeleteModal(addPromo.id)"
                        >
                            O'chirish
                        </button>
                    </div>


                    <div class="mb-3">
                        <label for="exampleInputId1" class="form-label">Qaysi kurs turi uchun?</label>
                        <b-form-select
                            name="hero"
                            class="form-control"
                            id="exampleInputId1"
                            v-model="addPromo.courseType"
                            multiple
                            :select-size="3"
                            required
                        >
                            <option
                                v-for="courseType of getCourseTypes"
                                :key="courseType.id"
                                :label="courseType.name"
                                :value="'/api/course_types/' + courseType.id"
                            ></option>
                        </b-form-select>
                    </div>


                    <label for="until" class="form-label">Amal qilish muddati</label>
                    <div class="mb-3 d-flex">
                        <input
                            id="until"
                            type="date"
                            class="form-control"
                            placeholder="Muddati"
                            v-model="addPromo.until"
                            required
                        >
                    </div>
                    <label for="discount" class="form-label">Chegirma</label>
                    <div class="mb-3 d-flex">

                        <b-form-input
                            id="discount"
                            class="form-control form-control-input col-7 col-sm-8 col-md-9"
                            placeholder="Chegirma foizi yoki summasi"
                            v-model="addPromo.discount"
                            :formatter="formatNumber"
                        >

                        </b-form-input>
                        <select
                            v-model="addPromo.isPercent"
                            class="form-select form-control-select col-5 col-sm-4 col-md-3 ps-3"
                            aria-label="Default select example"
                            required
                        >
                            <option value="" selected>Turini tanlang</option>
                            <option :value="true">Foiz</option>
                            <option :value="false">Summa</option>
                        </select>
                    </div>
                    <label for="amount" class="form-label">Miqdori</label>
                    <div class="mb-3 d-flex">
                        <input
                            id="amount"
                            type="number"
                            class="form-control"
                            placeholder="Miqdori"
                            v-model="addPromo.amount"
                            required
                        >
                    </div>

                    <div class="justify-content-end mb-3">
                        <button
                            class="btn btn-primary col-12"
                            :disabled="!addPromo.promo || !addPromo.amount || !addPromo.discount || !addPromo.until"
                            @click="changePromoCode"
                        >
                            Saqlash
                        </button>
                    </div>

                    <div class="justify-content-end mb-3">
                        <router-link :to="'/used-promo-code/' + this.$route.params.id" style="text-decoration: none">
                            <span class="texts btn btn-primary col-12">Ishlatilish tarixi</span>
                        </router-link>
                    </div>

                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Promokod muvaffaqiyatli o'zgartirildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>

                    <div>
                        <b-modal ref="modal-delete" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Ushbu promoni rostdan ham o'chirmoqchimisiz?</h3>
                            </div>
                            <b-button
                                class="mt-3"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="sm"
                                @click="hideErrorModal"
                            >
                                Yo'q
                            </b-button>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-danger"
                                size="sm"
                                @click="deletePromo"
                            >
                                Ha
                            </b-button>
                        </b-modal>
                    </div>

                </div>
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import accessMixin from "@/mixin/accessMixin";
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ChangePromo",
    mixins: [accessMixin],
    components: {GoBack, Quote},
    data() {
        return {
            addPromo: {
                id: null,
                promo: '',
                discount: 0,
                until: '',
                isPercent: false,
                amount: 100,
                promos: [],
                courseType: [],

            },
            show: true
        }
    },
    computed: {
        ...mapGetters(['getPromoCode', 'getPromo', 'getCourseTypes']),
    },
    methods: {
        ...mapActions(['putPromoCode', 'fetchCourseTypes', 'fetchPromoCode', 'deletePromoCode']),
        deletePromo() {
            this.deletePromoCode(this.$route.params.id)
                .then(() => {
                    this.$refs['modal-delete'].hide()
                    this.$router.push('/promo-code')
                    setTimeout(this.hideErrorModal, 3000)
                })
        },
        changePromoCode() {
            let discount = this.addPromo.discount + '00'
            discount.replace(/\s/g, "")
            let data = {
                id: this.addPromo.id,
                promo: this.addPromo.promo,
                discount: parseInt(discount.replace(/\s/g, "")),
                until: this.addPromo.until,
                isPercent: this.addPromo.isPercent,
                amount: parseInt(this.addPromo.amount),
                promos: this.addPromo.promos
            }
            this.putPromoCode(data)
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                })
        },
        showModal() {
            this.$refs['modal-success'].show()
        },
        showDeleteModal(id) {
            this.addPromo.id = id
            this.$refs['modal-delete'].show()
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$refs['modal-delete'].hide()
            this.$router.push('/promo-code')
        },
        formatNumber(n) {
            return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        },
        convertDateAnnouncement(inputFormat) {
            function pad(s) {
                return (s < 10) ? '0' + s : s;
            }

            let d = new Date(inputFormat)
            return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
        },
    },
    mounted() {
        this.show = true
        this.fetchCourseTypes()
        this.fetchPromoCode(this.$route.params.id)
            .then(() => {
                this.addPromo.id = this.getPromoCode.id
                this.addPromo.promo = this.getPromoCode.promo
                this.addPromo.discount = this.getPromoCode.discount / 100
                this.addPromo.until = this.convertDateAnnouncement(this.getPromoCode.until)
                this.addPromo.isPercent = this.getPromoCode.isPercent
                this.addPromo.amount = this.getPromoCode.amount
                this.addPromo.promos = this.getPromoCode.promos.map(el => el['@id'])
            })
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.mb-3 .form-control-select {
    border-radius: 0 10px 10px 0;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.mb-3 .form-control-input {
    border-radius: 10px 0 0 10px;
    border: solid #80007F 1px;
    border-right: none;
    font-family: Comfortaa, cursive;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-danger[data-v-0fcf5ccb] {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    padding-top: 8px;
    padding-bottom: 8px;
}

.btn-primary[data-v-0fcf5ccb] {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;
}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}

</style>
